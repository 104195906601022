// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAssessmentPage from "../component/WrapperAssessmentPage";

const mapStateToProps = (state) => ({
  surveys: state.surveys,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessmentById: (...args) => dispatch(Actions.getAssessmentById(...args)),
  updateInquirie: (...args) => dispatch(Actions.updateInquirie(...args)),
  updateAssessment: (...args) => dispatch(Actions.updateAssessment(...args)),
  getSurveys: (...args) => dispatch(Actions.getSurveys(...args)),
});
class AssessmentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getAssessmentById: PropTypes.func,
    updateAssessment: PropTypes.func,
    updateInquirie: PropTypes.func,
    surveys: PropTypes.array,
    getSurveys: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getSurveys } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      assessmentID: urlParams.id,
      loading: true,
    };

    getSurveys([
      { name: "surveys.assessmentID", comparison: "eq", value: urlParams.id },
    ]);
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { assessmentID } = this.state;
    const { getAssessmentById } = this.props;

    const resp = await getAssessmentById(assessmentID);

    this.setState({
      assessment: resp.payload,
      loading: false,
      refreshKey: Date.now(),
    });
  }

  getSurveys(filters) {
    const { getSurveys } = this.props;
    const { assessmentID } = this.state;
    return getSurveys([
      { name: "surveys.assessmentID", comparison: "eq", value: assessmentID },
      ...filters,
    ]);
  }

  render() {
    const {
      location,
      history,
      updateAssessment,
      updateInquirie,
      surveys,
      getSurveys,
    } = this.props;

    const { urlParams, loading, assessment, refreshKey } = this.state;

    return (
      <WrapperAssessmentPage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
        assessment={assessment}
        refreshKey={refreshKey}
        getSurveys={this.getSurveys.bind(this)}
        surveys={surveys}
        refresh={this.refresh.bind(this)}
        updateAssessment={updateAssessment}
        updateInquirie={updateInquirie}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPage);
