import { networkAction } from "helpers/network/networkAction";

import {
  GET_INQUIRIES,
  GET_INQUIRIE_BY_ID,
  DELETE_INQUIRIE,
  UPDATE_INQUIRIE,
  CREATE_INQUIRIE,
} from "constants/inquiries";

import * as Api from "api";

export const getInquiries = () => async (dispatch) =>
  networkAction(dispatch, GET_INQUIRIES, Api.getInquiries, []);

export const getInquirieById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_INQUIRIE_BY_ID, Api.getInquirieById, [id]);

export const deleteInquirie = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_INQUIRIE, Api.deleteInquirie, [id]);

export const createInquirie = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_INQUIRIE, Api.createInquirie, [body]);

export const updateInquirie = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_INQUIRIE, Api.updateInquirie, [id, body]);
