import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import Help from "@material-ui/icons/Help";
import { withStyles } from "@material-ui/styles";
import AssessmentInfo from "components/AssessmentInfo";
import AssessmentMarketing from "components/AssessmentMarketing";
// reactor
import Page from "components/Page";
import SurveysList from "components/SurveyListAdmin";
// constants
import { ASSESSMENTS, SURVEY } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperAssessmentPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    assessment: PropTypes.object,
    updateAssessment: PropTypes.object,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    updateInquirie: PropTypes.func,
    surveys: PropTypes.array,
    getSurveys: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      assessment,
      updateAssessment,
      updateInquirie,
      refreshKey,
      refresh,
      history,
      surveys,
      getSurveys,
    } = this.props;
    const { index } = this.state;

    switch (index) {
      case 1:
        return (
          <AssessmentInfo
            refreshKey={refreshKey}
            refresh={refresh}
            assessment={assessment}
            updateAssessment={updateAssessment}
            updateInquirie={updateInquirie}
          />
        );
      case 0:
        return (
          <AssessmentMarketing
            refreshKey={refreshKey}
            refresh={refresh}
            assessment={assessment}
            history={history}
            updateAssessment={updateAssessment}
            updateInquirie={updateInquirie}
          />
        );
      case 2:
        return (
          <SurveysList
            history={history}
            getSurveys={getSurveys}
            surveys={surveys}
            onSelect={(selectedSurvey) =>
              history.push(`${SURVEY}?id=${selectedSurvey.id}`)
            }
          />
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, assessment, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Assessment"
        loadingMessage={"Loading Assessment"}
        loading={loading}
        header={
          <Grid container style={{ padding: "10px 0px 0px 10px" }}>
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"All Assessments"}
                  className={classes.backChip}
                  onClick={() => history.push(ASSESSMENTS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                <Chip
                  label={assessment ? assessment.name : "..."}
                  className={classes.backChip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Help className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?id=${assessment.id}&index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              label="General"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Description Texts"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Profiles"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperAssessmentPage);
