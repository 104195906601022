import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BeenHere from "@material-ui/icons/BeenhereTwoTone";
import Store from "@material-ui/icons/StoreTwoTone";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
// constants
import { REACT_APP_CLIENT_EMAIL } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class AssessmentsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    assessments: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedAssessment) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedAssessment);
    }
  }

  render() {
    const { assessments, classes } = this.props;

    if (assessments.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Grid item>
            <BeenHere
              style={{ fontSize: 70 }}
              className={classes.darkModeFix}
            />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No Licences.
            </Typography>
            <Typography display="block" variant="body" color="secondary">
              Email us to set up a new license.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              style={{
                textTransform: "none",
                marginTop: 20,
              }}
              onClick={() => window.open(`mailto:${REACT_APP_CLIENT_EMAIL}`)}
            >
              <Store style={{ paddingRight: 10 }} /> Contact Us
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={assessments}
        rowStyle={(d) => ({
          borderLeft: `solid 3px ${d.color}`,
          opacity: d.active ? 1 : 0.5,
        })}
        meta={[
          {
            path: "icon",
            render: (t) => (
              <img
                src={t}
                alt="assessment"
                style={{ height: 40 }}
                className={classes.darkMode}
              />
            ),
            width: 30,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  color="textSecondary"
                  variant="caption"
                >
                  {d.active ? "Active" : "Not Active"}
                </Typography>
              </React.Fragment>
            ),
          },
        ]}
        title={"Assessments"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(AssessmentsList);
