// routes
import cpfm from "assets/img/in.png";
import {
  ASSESSMENTS,
  ENROLLMENTS,
  EVENTS,
  MARKET,
  MY_ACCOUNT,
  STUDENTS,
  SURVEYS,
  TEAMS,
} from "constants/routes";
import React from "react";
import { CAREERS_IN_MUSIC } from "./env";

export const menu = [
  {
    icon: <i class="fad fa-store"></i>,
    label: "Market",
    path: MARKET,
    divider: true,
  },
  {
    icon: <i class="fad fa-bags-shopping"></i>,
    label: "My Purchases",
    path: ENROLLMENTS,
    divider: true,
    nested: [
      {
        icon: <i class="fad fa-chalkboard"></i>,
        label: "Courses",
        path: ENROLLMENTS,
        divider: true,
      },
      {
        icon: <i class="fad fa-poll-h"></i>,
        label: "Assessments",
        path: SURVEYS,
        divider: true,
      },
      {
        icon: <i class="fad fa-video"></i>,
        label: "Private Sessions",
        path: EVENTS,
        divider: true,
      },
    ],
  },
  {
    icon: <i class="fad fa-file-certificate"></i>,
    label: "Licences",
    path: ASSESSMENTS,
    divider: false,
  },
  {
    icon: <i class="fad fa-users-class"></i>,
    label: "Students",
    path: STUDENTS,
    divider: true,
  },
  {
    icon: <i class="fad fa-users"></i>,
    label: "Teams",
    path: TEAMS,
    divider: true,
  },
  {
    icon: <i class="fad fa-user-circle"></i>,
    label: "My Account",
    path: MY_ACCOUNT,
    divider: true,
  },
];

export const menuWithCPFM = [
  ...menu.splice(0, 2),
  {
    icon: <img src={cpfm} height={25} style={{ filter: "grayscale(100%)" }} />,
    label: "Careers in Music",
    path: ENROLLMENTS,
    divider: true,
    nested: [
      {
        icon: <i class="fa-solid fa-clipboard-question"></i>,
        label: "Quiz Results",
        href: `${CAREERS_IN_MUSIC}?myQuizesOpen=true`,
        divider: true,
      },
      {
        icon: <i class="fa-solid fa-heart"></i>,
        label: "Favorites",
        href: `${CAREERS_IN_MUSIC}?favoriteOpen=true`,
        divider: true,
      },
    ],
  },
  ...menu.splice(2, menu.length - 1),
];
