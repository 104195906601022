import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import assessments from "./assessments";
import cards from "./cards";
import cart from "./cart";
import categories from "./categories";
import courses from "./courses";
import credits from "./credits";
import currentStudent from "./currentStudent";
import customers from "./customers";
import enrollments from "./enrollments";
import errors from "./errors";
import goods from "./goods";
import myStudent from "./myStudent";
import payments from "./payments";
import searchPresets from "./searchPresets";
import surveys from "./surveys";
import teams from "./teams";
import teamUsers from "./teamUsers";
import timezones from "./timezones";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
export default combineReducers({
  errors,
  user,
  app,
  users,
  goods,
  userApps,
  courses,
  enrollments,
  cards,
  cart,
  payments,
  customers,
  searchPresets,
  categories,
  surveys,
  assessments,
  teamUsers,
  teams,
  credits,
  currentStudent,
  myStudent,
  timezones,
});
