import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import MarkdownEditor from "components/MarkdownEditor";
// routes
import { ASSESSMENTS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class AssessmentInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    updateAssessment: PropTypes.func,
    deleteAssessment: PropTypes.func,
    updateInquirie: PropTypes.func,
    getAssessmentById: PropTypes.func,
    assessment: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    app: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { assessment } = this.props;
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      ...assessment,
      loading: false,
    };
  }

  componentWillUnmount() {
    const { UploadCenter } = this.context;
    UploadCenter.disregard();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleColorChange = (name) => (color) => {
    this.setState({ [name]: color.hex });
  };

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          this.setState({
            icon: r.payload,
          });
          this.save("icon");
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async save(name, numeric) {
    const { updateAssessment, refresh, assessment } = this.props;

    if (assessment[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateAssessment(assessment.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async saveInquiry(name, numeric) {
    const { updateInquirie, refresh, assessment } = this.props;

    this.setState({
      loading: true,
    });

    await updateInquirie(assessment.inquiry.id, {
      [name]: numeric
        ? Number(this.state.inquiry[name])
        : this.state.inquiry[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteAssessment, refresh, assessment, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteAssessment(assessment.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Assessment has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      history.push(ASSESSMENTS);
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes } = this.props;

    const {
      loading,
      name,
      description,
      instructions,
      idealFor,
      nextSteps,
      profileExplanation,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <Typography display="block" variant="h6">
              Name
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              What is the assessment's name ?
            </Typography>
            <TextField
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
              disabled={loading}
              fullWidth
              multiline
              onBlur={() => this.save("name")}
              className={classes.inputBase}
              value={name}
              onChange={this.handleChange("name")}
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography display="block" variant="h6">
              Description
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Why should a student take the assessment ?
            </Typography>
            <MarkdownEditor
              value={description}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("description");
              }}
              onBlur={() => this.save("description")}
              disabled={loading}
              onChange={this.handleChange("description")}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography display="block" variant="h6">
              Profile Explanation
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              What does the generated profile mean ?
            </Typography>
            <MarkdownEditor
              value={profileExplanation}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("profileExplanation");
              }}
              onBlur={() => this.save("profileExplanation")}
              disabled={loading}
              onChange={this.handleChange("profileExplanation")}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography display="block" variant="h6">
              Ideal For
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              What kind of people should take the assessment ?
            </Typography>
            <MarkdownEditor
              value={idealFor}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("idealFor");
              }}
              onBlur={() => this.save("idealFor")}
              disabled={loading}
              onChange={this.handleChange("idealFor")}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography display="block" variant="h6">
              Instructions
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              How to answer the assessment ?
            </Typography>
            <MarkdownEditor
              value={instructions}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("instructions");
              }}
              onBlur={() => this.save("instructions")}
              disabled={loading}
              onChange={this.handleChange("instructions")}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography display="block" variant="h6">
              Next Steps
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              What should the student do after taking the assessment ?
            </Typography>
            <MarkdownEditor
              value={nextSteps}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("nextSteps");
              }}
              onBlur={() => this.save("nextSteps")}
              disabled={loading}
              onChange={this.handleChange("nextSteps")}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AssessmentInfo);
