export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    padding: 20,
    borderLeft: `solid 3px ${theme.palette.primary.color[600]}`,
  },
  dangerPaper: {
    padding: 20,
    borderLeft: "solid 3px #f44336",
  },
  paperLoading: {
    padding: 20,
    borderLeft: "solid 3px #212121",
  },
  info: {
    padding: 20,
    borderLeft: "solid 3px #2196f3",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.paper,
  },
  lock: {
    height: 15,
  },
});
