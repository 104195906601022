import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// routes
import { ASSESSMENTS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
// styles
import preview from "./preview.png";
import styles from "./styles";

class AssessmentMarketing extends Component {
  static propTypes = {
    classes: PropTypes.object,
    updateAssessment: PropTypes.func,
    deleteAssessment: PropTypes.func,
    updateInquirie: PropTypes.func,
    getAssessmentById: PropTypes.func,
    assessment: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    app: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { assessment } = this.props;
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      ...assessment,
      loading: false,
    };
  }

  componentWillUnmount() {
    const { UploadCenter } = this.context;
    UploadCenter.disregard();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleColorChange = (name) => (color) => {
    this.setState({ [name]: color.hex });
  };

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          this.setState({
            icon: r.payload,
          });
          this.save("icon");
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async save(name, numeric) {
    const { updateAssessment, refresh, assessment } = this.props;

    if (assessment[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateAssessment(assessment.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async saveInquiry(name) {
    const { updateInquirie, refresh, assessment } = this.props;

    this.setState({
      loading: true,
    });

    await updateInquirie(assessment.inquiry.id, {
      [name]: this.state.inquiry[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteAssessment, refresh, assessment, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteAssessment(assessment.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Assessment has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      history.push(ASSESSMENTS);
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes } = this.props;

    const { loading, icon, color } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography display="block" variant="h6">
              Marketing
            </Typography>
            {icon ? (
              <img
                src={icon}
                alt="assessment-logo"
                style={{
                  height: 75,
                  background: "#FFFFFF",
                  borderRadius: 4,
                  padding: 2,
                }}
              />
            ) : (
              []
            )}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Icon URL:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Drag and drop to upload an icon.
                </Typography>
                <TextField
                  variant="outlined"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("icon");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("icon")}
                  className={classes.inputBase}
                  value={icon}
                  onChange={this.handleChange("icon")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Color:
                </Typography>
                <SketchPicker
                  color={color || ""}
                  disableAlpha
                  onChange={this.handleColorChange("color")}
                  onChangeComplete={() => this.save("color")}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div style={{ position: "relative" }}>
            <img
              alt="preview"
              src={preview}
              style={{
                width: "100%",
                background: color,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Inquiry
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Before a user can take an assessment, he will have to specify the
              checked items.
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Activity:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  User's current activity
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.activity}
                  onChange={(e) => {
                    this.state.inquiry.activity = e.target.checked; //eslint-disable-line
                    this.saveInquiry("activity");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Age:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  User's age
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.age}
                  onChange={(e) => {
                    this.state.inquiry.age = e.target.checked; //eslint-disable-line
                    this.saveInquiry("age");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Children:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  How many Children does the User have ?
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.children}
                  onChange={(e) => {
                    this.state.inquiry.children = e.target.checked; //eslint-disable-line
                    this.saveInquiry("children");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Hours:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  How many hours per week does the user train ?
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.hours}
                  onChange={(e) => {
                    this.state.inquiry.hours = e.target.checked; //eslint-disable-line
                    this.saveInquiry("hours");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Relationship:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  User's current relationship
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.relationship}
                  onChange={(e) => {
                    this.state.inquiry.relationship = e.target.checked; //eslint-disable-line
                    this.saveInquiry("relationship");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Sex:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  User's gender
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.sex}
                  onChange={(e) => {
                    this.state.inquiry.sex = e.target.checked; //eslint-disable-line
                    this.saveInquiry("sex");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Years:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Years of experience
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={this.state.inquiry.years}
                  onChange={(e) => {
                    this.state.inquiry.years = e.target.checked; //eslint-disable-line
                    this.saveInquiry("years");
                  }}
                  disabled={loading}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={6}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Export
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Export the assessment in order to save it or import it later in a different organization.
            </Typography>
            <Button
              onClick={async () => {
                const resp = await getAssessmentById(assessment.id);
                const n = resp.payload;
                fileDownload(JSON.stringify(n, null, '\t'), `${n.name}.json`);
              }}
              color="primary"
              variant="raised"
            >
              <CloudDownload style={{ marginRight: 15 }} /> Export
            </Button>
          </Paper>
          <Paper
            className={loading ? classes.paperLoading : classes.dangerPaper}
            style={{ marginTop: 32 }}
          >
            <Typography display="block" variant="h6">
              Danger Zone
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Delete the Assessment.
            </Typography>
            <Button
              onClick={this.delete.bind(this)}
              style={{ color: 'white', background: '#f44336' }}
              variant="raised"
            >
              <Delete style={{ marginRight: 15 }} /> Delete
            </Button>
          </Paper>
        </Grid> */}
      </Grid>
    );
  }
}

export default withStyles(styles)(AssessmentMarketing);
