import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const inquiries = `/${service}/inquiries`;

export function getInquiries() {
  const url = `${inquiries}`;
  return {
    method: GET,
    url,
  };
}

export function getInquirieById(id) {
  const url = `${inquiries}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateInquirie(id, body) {
  const url = `${inquiries}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInquirie(id) {
  const url = `${inquiries}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInquirie(body) {
  const url = `${inquiries}`;
  return {
    method: POST,
    url,
    body,
  };
}
